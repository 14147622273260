<template>
  <BaseSelect
    :id="id"
    :value="value"
    :options="options"
    label="name"
    :reduce="reduce"
    :clearable="clearable"
    :hide-borders="hideBorders"
    @selected="onSelected"
    @input="$emit('input', $event)"
  >
    <template #selected-option="{ name }">
      <StatusBadge
        :status="name"
      />
    </template>
  </BaseSelect>
</template>

<script>
import StatusApi from '@/api/status-api'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'StatusSelect',
  components: { BaseSelect, StatusBadge },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    reduce: {
      type: Function,
      default: item => item,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    hideBorders: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
    }
  },
  async mounted() {
    try {
      const response = await StatusApi.list(this.type)
      this.options = response.data.data
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    onSelected(selectedOption) {
      this.$emit('selection-change', selectedOption)
    },
  },
}
</script>

<style scoped>
.v-select {
  min-width: 11.3em;
}
</style>
